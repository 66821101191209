import React from 'react';
import { AboutContainer, AboutContent } from './styled';
import TextAndImageComponent from '../TextAndImageComponent/TextAndImageComponentContainer';

const About = ({image, text, imageOrder, title}) => {
	return(
		<AboutContainer id={'about'}>
			<AboutContent>
				<TextAndImageComponent
					image={image}
					text={text}
					imageOrder={imageOrder}
					title={title}
				/>
			</AboutContent>
		</AboutContainer>
	);
};

export default About;
