import React, { memo } from 'react';
import {StyledSlide, SlideImage} from './styled';

const Slide = ({ position, transition, image }) => {
	return (
		<StyledSlide position={position} transition={transition}>
			<SlideImage src={image} alt='Mile High Gamers Club'/>
		</StyledSlide>
	);
};

export default memo(Slide);
