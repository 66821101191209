import React from 'react';
import { HomeLinkContainer, IconContainer, StyledLink, Logo } from './styled';
import MHGC from '../../../../assets/images/mhgc.png';

const HomeLink = () => {
	return (
		<HomeLinkContainer>
			<StyledLink to='/'>
				<IconContainer>
					<Logo src={MHGC} alt={''}/>
				</IconContainer>
			</StyledLink>
		</HomeLinkContainer>
	);
};

export default HomeLink;
