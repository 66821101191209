import React from 'react';
import PropTypes from 'prop-types';

import Header from './components/Layout/Header/HeaderContainer';
import Footer from './components/Layout/Footer/FooterContainer';
import './components/layout.css';
import { GlobalStyle } from './globalStyle';

const Layout = ({ children }) => {

	return (
		<>
			<Header/>
			<GlobalStyle/>
			<main>{children}</main>
			<Footer/>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
