import React from 'react';
import { ImageContentContainer, StyledImage } from './styled';

const ImageContent = ({image, imageOrder, inView}) => {
	return (
		<ImageContentContainer order={imageOrder} inView={inView}>
			<StyledImage src={image} alt=''/>
		</ImageContentContainer>
	);
};

export default ImageContent;
