import styled from 'styled-components';

const  Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

export {Container};
