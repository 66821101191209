import React, { useEffect, useRef, useState } from 'react';
import { TextAndImageComponentContainer, TextAndImageContentContainer } from './styled';
import TextContent from '../TextContent';
import ImageContent from '../ImageContent';
import { useScrollPosition } from '../../../../hooks/useScrollPosition';

const TextAndImageComponent = ({title, text, image, imageOrder}) => {
	const [inView, setInView] = useState(false);
	const [elementPosition, setElementPosition] = useState({ x: 0, y: 1500 });
	const elementRef = useRef();

	useScrollPosition(
		({ currPos }) => {
			setElementPosition(currPos);
		}, [], elementRef
	);

	useEffect(() => {
		if(elementPosition.y < window.innerHeight * 0.66){
			setInView(true);
		}
	}, [elementPosition]);

	return (
		<TextAndImageComponentContainer ref={elementRef}>
			<TextAndImageContentContainer>
				<TextContent
					text={text}
					imageOrder={imageOrder}
					inView={inView}
					title={title}
				/>
				<ImageContent
					image={image}
					imageOrder={imageOrder}
					inView={inView}
				/>
			</TextAndImageContentContainer>
		</TextAndImageComponentContainer>
	);
};

export default TextAndImageComponent;
