import styled from 'styled-components';

const MainLinksContainer = styled.div`
	width: 100%;
	padding: 1.5rem 0 1.5rem 0;

	@media only screen and (min-width: 769px){
		width: 33%;
		padding: 0.65rem 2rem 1.5rem 0;
	}
`;

const FooterCopy = styled.div`
	color: #f2f2f2;
	text-transform: uppercase;
	display: inline;
	font-size: 13px;
	letter-spacing: 0.07em;
	align-self: flex-end;
	a {
		color: #5f686f;
		text-decoration: none;
		&:hover {
			color: #919eab;
		}
	}
	@media only screen and (min-width: 769px){
		font-size: 11px;
		letter-spacing: 0.06em;
		line-height: 1.18;
	}
`;

export { MainLinksContainer, FooterCopy };
