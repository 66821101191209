/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = () => {
	return (
		<Helmet>
			<title>Mile High Gamers Club</title>
			<meta name="description" content="Mile High Gamers Club on elektronisen urheilun yhdistys, jonka jäsenet ovat suomalaisia ammattilentäjiä. Jäsenet ovat töissä eri organisaatioissa, mutta heitä yhdistää suomalainen lentäjäkulttuuri ja rakkaus pelaamiseen." />
		</Helmet>
	);
};

export default SEO;