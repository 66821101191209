import styled from 'styled-components';

const TextAndImageComponentContainer = styled.section`
	background-color: #f2f2f2;
	padding: 36px 40px;

	@media only screen and (min-width: 426px) {
		padding: 36px 24px;
	}

	@media only screen and (min-width: 769px) {
		padding: 74px 24px;
	}
`;

const TextAndImageContentContainer = styled.div`
	max-width: 1440px;
	margin: 0 auto;

	@media only screen and (min-width: 426px) {
		display: flex;
		justify-content: space-between;
		padding: 0 24px;
		align-items: center;
	}
`;

export { TextAndImageComponentContainer, TextAndImageContentContainer };
