import React from 'react';
import { FollowContainer, FollowHashtag, FollowHeading } from './styled';
import FollowSomeLinks from '../FollowSoMeLinks';

const Follow = ({heading, hashtag}) => {
	return(
		<FollowContainer id={'followus'}>
			<FollowHeading>{heading}</FollowHeading>
			<FollowSomeLinks/>
			<FollowHashtag>{hashtag}</FollowHashtag>
		</FollowContainer>
	);
};

export default Follow;
