import React from 'react';
import Slide from '../Slide';
import {Container} from './styled';
import {useSlider} from '../../../hooks/useSlider';

const initialState =
{
	slide1: {
		id: 0,
		position: 'onScreen',
		transition: true
	},
	slide2: {
		id: 1,
		position: 'offScreenRight',
		transition: true
	},
	currentId: 0
};

const SlideShow = ({slides}) => {
	const slideState = useSlider(initialState, slides.length);

	return (
		<Container>
			<Slide
				image={slides[slideState.slide1.id]}
				position={slideState.slide1.position}
				transition={slideState.slide1.transition}
			/>
			<Slide
				image={slides[slideState.slide2.id]}
				position={slideState.slide2.position}
				transition={slideState.slide2.transition}
			/>
		</Container>
	);
};

export default SlideShow;
