import styled from 'styled-components';

const Container = styled.div`
    width: 100vw;
    height: 36vh;
    display: flex;
    flex-direction: column;
    
    
    @media only screen and (min-width: 769px){
      height: 36vh;
    }
    
    @media only screen and (min-width: 1200px){
      height: 80vh;
    }
`;

const Main = styled.div`
    flex: 8;
`;

export {Container, Main};
