import React from 'react';
import { FormContentContainer, HeaderContainer, StyledHeader, FormContainer, StyledInput, SubmitContainer, SubmitButton, StyledTextArea } from './styled';

const FormContent = () => {
	return (
		<FormContentContainer>
			<HeaderContainer>
				<StyledHeader>contact</StyledHeader>
			</HeaderContainer>
			<FormContainer>
				<form target='_blank' action='https://formsubmit.co/be3bed51e839151d234f43bf6ad4b107' method='POST'>
					<input type='hidden' name='_next' value='https://mhgc.fi/thankyou'/>
					<input type='text' name='_honey' style={{display: 'none'}}/>
					<input type='hidden' name='_captcha' value='false'/>
					<StyledInput type='text' name='name' placeholder='Nimi' required/>
					<StyledInput type='email' name='email' placeholder='Email' required/>
					<StyledTextArea name='message' placeholder='Laita meille viestiä!' required/>
					<SubmitContainer>
						<SubmitButton type='submit'>Lähetä</SubmitButton>
					</SubmitContainer>
				</form>
			</FormContainer>
		</FormContentContainer>
	);
};

export default FormContent;
