import styled from 'styled-components';

const PageFooter = styled.footer`
	position: relative;
	width: 100%;
	box-sizing: border-box;
	padding: 0.2rem 1.5rem;
	display: flex;
	flex-wrap: wrap;
	background-color: #074368;

	a {
		letter-spacing: 0.021em;
		text-decoration: none;
		color: #919eab;
		font-size: 16px;

		@media only screen and (min-width: 769px){
			color: #5f686f;
			font-size: 13px;
		}
	}
	@media only screen and (min-width: 769px){
		flex-wrap: nowrap;
	}
`;

export { PageFooter };
