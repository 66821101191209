import { useRef, useLayoutEffect } from 'react';

/* Check if the DOM is ready and the window context exists. */
const isBrowser = typeof window !== 'undefined';

/* either return scroll position of the entire page or specific element inside it. */
function getScrollPosition({ element, useWindow }) {
	if (!isBrowser) return { x: 0, y: 0 };

	const target = element ? element.current : document.body;
	const position = target.getBoundingClientRect();

	return useWindow
		? { x: window.scrollX, y: window.scrollY }
		: { x: position.left, y: position.top };
}


/* The actual hook.  */
export function useScrollPosition(effect, deps, element, useWindow, wait) {
	/* Store current position coordinates in stateful variable {x: ?, y: ?} */
	const position = useRef(getScrollPosition({ useWindow }));

	let throttleTimeout = null;

	const callBack = () => {
		const currPos = getScrollPosition({ element, useWindow });
		effect({ prevPos: position.current, currPos });
		position.current = currPos;
		throttleTimeout = null;
	};

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (wait) {
				if (throttleTimeout === null) {
					throttleTimeout = setTimeout(callBack, wait);
				}
			} else {
				callBack();
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, deps);
}
