import React from 'react';
import { NavigationList, NavigationContainer } from './styled';
import PrimaryNavigationItem from '../PrimaryNavigationItem';

const PrimaryNavigationContainer = () => {
	return (
		<NavigationContainer>
			<NavigationList>
				<PrimaryNavigationItem name={'About'} uri={'/#about'}/>
				<PrimaryNavigationItem name={'Follow us'} uri={'/#followus'}/>
				<PrimaryNavigationItem name={'Contact'} uri={'/#contact'}/>
			</NavigationList>
		</NavigationContainer>
	);
};

export default PrimaryNavigationContainer;
