import React from 'react';
import SlideShow from '../SlideShow';
import {Container, Main} from './styled';

const SlideShowContainer = ({slides}) => {
	return (
		<Container>
			<Main>
				<SlideShow slides={slides}/>
			</Main>
		</Container>
	);
};
export default SlideShowContainer;
