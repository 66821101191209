import styled from 'styled-components';
import Pilot from '../../../assets/svgs/pilot.svg';

function getTransformY(positionY){
	return positionY > 1000 ? 0 : -(positionY - 1000) / 5;
}

const ContactFormContainer = styled.div`
	padding: 48px 24px;
	position: relative;
	overflow: hidden;
	background-color: #f2f2f2;

	@media only screen and (min-width: 426px) {
		background-position: 85% -50%;
		padding-top: 72px;
		padding-bottom: 72px;
	}
`;

const PilotContainer = styled.div`
	visibility: hidden;
	position: absolute;
	right: 300px;
	top: 50px;
	overflow: hidden;
	pointer-events: none;
	z-index: 0;
	width: 400px;
	height: 900px;
	transform: ${props => 'translateY(' + getTransformY(props.position) + 'px)'};
	transition: transform 0.7s ease;

	@media only screen and (min-width: 769px) {
		top: -50px;
	}
	@media only screen and (min-width: 1500px) {
		visibility: visible;
	}
`;

const PilotImage = styled(Pilot)`
	opacity: .5;
	width: 100%;
`;

export { ContactFormContainer, PilotContainer, PilotImage };
