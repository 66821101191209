import React from 'react';
import { MainLinksContainer, FooterCopy } from './styled';

const FooterMainLinks = () => {
	return(
		<MainLinksContainer>
			<FooterCopy>© {new Date().getFullYear()} Mile High Gamers Club</FooterCopy>
		</MainLinksContainer>
	);
};

export default FooterMainLinks;
