import React from 'react';
import { YoutubeButton, TwitchButton, InstagramButton } from './socialMediaButtons';
import {SocialMediaIconsListContainer} from './styled';

const FollowSomeLinks = () => {
	return(
		<SocialMediaIconsListContainer>
			<TwitchButton/>
			<InstagramButton/>
			<YoutubeButton/>
		</SocialMediaIconsListContainer>
	);
};

export default FollowSomeLinks;
