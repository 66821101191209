import React from 'react';
import {
	StyledInstagramIcon,
	StyledTwitchIcon,
	StyledYoutubeIcon,
	IconListItem,
	SocialMediaIconLink
} from './styled';

const TwitchButton = () => (
	<IconListItem>
		<SocialMediaIconLink href={'https://www.twitch.tv/milehighgamersclub'} target='_blank' rel='noopener noreferrer'>
			<StyledTwitchIcon />
		</SocialMediaIconLink>
	</IconListItem>
);

const InstagramButton = () => (
	<IconListItem>
		<SocialMediaIconLink href={'https://www.instagram.com/milehighgamersclub/'} target="_blank" rel='noopener noreferrer'>
			<StyledInstagramIcon />
		</SocialMediaIconLink>
	</IconListItem>
);

const YoutubeButton = () => (
	<IconListItem>
		<SocialMediaIconLink href={'https://www.youtube.com/channel/UCbWmAEDIdhDSUVBMOGQnhYw'} target="_blank" rel='noopener noreferrer'>
			<StyledYoutubeIcon/>
		</SocialMediaIconLink>
	</IconListItem>
);

export { TwitchButton, InstagramButton, YoutubeButton };
