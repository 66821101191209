import styled from 'styled-components';

const NavigationContainer = styled.div`

  @media screen and (min-width: 1024px){
    padding-left: 7rem;
  }
`;

const NavigationList = styled.ul`
	display: block;
	height: 100%;
	margin: 0;

	@media screen and (min-width: 924px){
		display: table;
	}

`;

export { NavigationContainer, NavigationList };
