import React from 'react';
import { HamburgerContainer, StyledSpan } from './styled';

const NavigationButton = ({toggleNav, open}) => {

	const toggleMenu = () => {
		toggleNav();
	};

	return (
		<HamburgerContainer onClick={toggleMenu}>
			<StyledSpan open={open}/>
			<StyledSpan open={open}/>
			<StyledSpan open={open}/>
		</HamburgerContainer>
	);
};

export default NavigationButton;
