import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const NavigationItem = styled.li`
	vertical-align: middle;
	margin: 0;
	height: 50px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	white-space: nowrap;
	letter-spacing: .01rem;

	@media screen and (min-width: 924px){
		display: table-cell;
		font-size: 0.8rem;
	}
`;

const StyledAnchorLink = styled(AnchorLink)`
	display: block;
	color: #e0e0e0;
	text-decoration: none;
	padding: 12px 0;
	margin: 0 24px;
	border-bottom: 1px solid rgba(224,224,224,.2);

	@media screen and (min-width: 924px){
		position: relative;
		display: inline-block;
		padding: 1.2rem 1.2rem 0 1.2rem;
		margin: 0;
		font-size: 1rem;
		border-bottom: none;

		&:after {
			position: inherit;
			content: "";
			display: block;
			margin-top: 1.2rem;
			height: 2px;
			top: 11px;
			left: 50%;
			background: #a41616;
			transition: width 0.3s ease 0s, left 0.3s ease 0s;
			width: 0;
		}

		&:hover:after {
			width: 100%;
			left: 0;
		}
	}
`;

export { NavigationItem, StyledAnchorLink };
