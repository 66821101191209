import styled from 'styled-components';

const FormContentContainer = styled.div`
	padding-bottom: 48px;
	max-width: 1040px;
	margin: 0 auto;

	@media only screen and (min-width: 426px) {
		padding: 0 24px 72px;
	}
`;

const HeaderContainer = styled.header`
	max-width: 600px;
`;

const StyledHeader = styled.h2`
	color: #074368;
	font-family: Varsity, sans-serif;
	font-size: 40px;
	letter-spacing: 0.2em;
`;

const FormContainer = styled.div`
	max-width: 600px;
	position: relative;
`;

const StyledInput = styled.input`
	height: 24px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 14px;
	appearance: none;
	width: 80%;
	display: block;
	border-radius: 5px;
	line-height: 40px;
	margin-bottom: 12px;
	letter-spacing: .04em;
	box-shadow: none;
	outline: none;
	border: 0;
	padding: 17px 20px;
	color: #fff;
	background-color: #005899;

	&::placeholder {
		color: #fff;
		opacity: 0.8;
	}

	@media only screen and (min-width: 426px) {
		display: inline-block;
		width: 45%;
		margin-right: 10px;
	}

	@media only screen and (min-width: 769px) {
		width: 40%;
	}
`;

const SubmitContainer = styled.div`
	text-align: center;
	padding-top: 24px;

	@media only screen and (min-width: 769px) {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 50%;
		box-sizing: border-box;
		padding-left: 6px;
		margin-right: 32px;
		text-align: right!important;
	}
`;

const SubmitButton = styled.button`
	display: inline-block;
	position: relative;
	background-color: transparent;
	border: 2px solid #074368;
	min-width: 180px;
	margin: 0 auto;
	top: 40px;
	font-size: 14px;
	border-radius: 5px;
	color: #074368;
	padding: 18px 24px;
	line-height: 1.25;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
	transition: transform .25s cubic-bezier(.25,.46,.45,.94);

	&:hover {
		background-color: #6d1212;
		border: 1px solid #6d1212;
		transform: translateY(-1px);
		box-shadow: 0 4px 12px rgba(0,0,0,.1);
		color: #ffffff;
	}
	
	@media only screen and (min-width: 769px) {
	  top: 70px;
	}
`;

const StyledTextArea = styled.textarea`
	display: block;
	height: auto;
	width: 80%;
	min-height: 140px;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-weight: 400;
	appearance: none;
	border-radius: 5px;
	line-height: 40px;
	margin-bottom: 12px;
	letter-spacing: .04em;
	box-shadow: none;
	outline: none;
	border: 0;
	padding: 17px 20px;
	color: #fff;
	background-color: #005899;

	&::placeholder {
		color: #fff;
		opacity: 0.8;
	}

	@media only screen and (min-width: 426px) {
		width: 92%;
	}

	@media only screen and (min-width: 769px) {
		width: 88%;
	}
`;

export { FormContentContainer, HeaderContainer, StyledHeader, FormContainer, StyledInput, SubmitContainer, SubmitButton, StyledTextArea };
