import styled from 'styled-components';

const HamburgerContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 4rem;
	height: 4rem;
	cursor: pointer;

	@media screen and (min-width: 924px){
		display: none;
	}

`;

const StyledSpan = styled.span`
	width: 22px;
	height: 1px;
	background-color: #e0e0e0;
	display: block;
	position: absolute;
	right: 24px;
	top: 30px;
	transition: 0.5s;

	&:nth-child(1){
		transform: ${props => props.open ? 'translateY(0) rotate(45deg)' : 'translateY(-6px) rotate(0deg)'};
	}
	&:nth-child(2){
		transform: ${props => props.open ? 'scale(0) rotate(45deg)' : 'scale(1) rotate(0deg)'};
	}
	&:nth-child(3){
		transform: ${props => props.open ? 'translateY(0) rotate(-45deg)' : 'translateY(6px) rotate(0deg)'};
	}
`;

export { HamburgerContainer, StyledSpan };
