import {useState, useEffect} from 'react';

export const useSlider = (slides, amount) => {
	const [slideState, setSlideState] = useState(slides);
	const [slideAmount] = useState(amount);

	useEffect(() => {
		const interval = setInterval(() => {
			transitionSlides(slideState.slide1, slideState.slide2);
		}, 4000);
		return () => clearInterval(interval);
	});

	const transitionSlides = (slide1, slide2) => {
		let currentId;
		if (slide1.position === 'onScreen') {
			slide1.position = 'offScreenLeft';
			slide2.position = 'onScreen';
			currentId = slide2.id;
		} else {
			slide1.position = 'onScreen';
			slide2.position = 'offScreenLeft';
			currentId = slide1.id;
		}
		setSlideState({slide1, slide2, currentId});
		setTimeout(() => {
			resetSlideOffScreen(slide1, slide2, currentId);
		}, 1000);
	};

	const resetSlideOffScreen = (slide1, slide2, currentId) => {

		if (slide1.position === 'offScreenLeft') {
			slide1.transition = false;
			slide1.position = 'offScreenRight';
			slide1.id = slide2.id + 1 === slideAmount ? 0 : slide2.id + 1;
		} else {
			slide2.transition = false;
			slide2.position = 'offScreenRight';
			slide2.id = slide1.id + 1 === slideAmount ? 0 : slide1.id + 1;
		}
		setSlideState({slide1, slide2, currentId});
		resetSlideTransitions(slide1, slide2, currentId);
	};

	const resetSlideTransitions = (slide1, slide2, currentId) => {
		setTimeout(() => {
			slide1.transition = true;
			slide2.transition = true;
			setSlideState({slide1, slide2, currentId});
		}, 500);
	};

	return slideState;
};
