import styled from 'styled-components';

const AboutContainer = styled.div`
  width: 100vw;
  background-color: #f2f2f2;
`;

const AboutContent = styled.div`
  margin: 0; 
`;

export { AboutContainer, AboutContent };
