import styled from 'styled-components';

const FollowContainer = styled.div`
  width: 100vw;
  background-color: #074368;
  padding-bottom: 80px;
`;

const FollowHeading = styled.h2`
	width: 100%;
	margin: auto 0 24px;
	font-family: 'Varsity', sans-serif;
  color: #ffffff;
  font-size: 40px;
  letter-spacing: 0.2em;
  padding-top: 80px;
  padding-bottom: 20px;
  text-align: center;

	@media only screen and (min-width: 426px) {
		margin-bottom: 36px;
	}
`;

const FollowHashtag = styled.h3`
	width: 100%;
	margin: auto 0 24px;
  color: #ffffff;
  font-size: 30px;
  letter-spacing: 0.2em;
  padding-top: 30px;
  text-align: center;
`;

export { FollowContainer, FollowHeading, FollowHashtag };
