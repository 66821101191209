import React, { useState } from 'react';
import { HeaderContainer, NavigationContainer } from './styled';
import HomeLink from '../HomeLink';
import PrimaryNavigationContainer from '../PrimaryNavigation/PrimaryNavigationContainer';
import NavigationButton from '../NavigationButton';

const Header = () => {
	const [open, setOpen] = useState(false);

	function toggleNav(){
		open ? setOpen(false) : setOpen(true);
	}

	return(
		<HeaderContainer>
			<HomeLink/>
			<NavigationContainer open={open}>
				<PrimaryNavigationContainer/>
			</NavigationContainer>
			<NavigationButton
				toggleNav={toggleNav}
				open={open}
			/>
		</HeaderContainer>
	);
};

export default Header;
