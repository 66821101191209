import React, { useRef, useState } from 'react';
import { ContactFormContainer, PilotContainer, PilotImage } from './styled';
import FormContent from '../FormContent';
import { useScrollPosition } from '../../../hooks/useScrollPosition';

const ContactForm = () => {
	const [position, setPosition] = useState({x: 0, y: 5000});
	const elementRef = useRef();

	useScrollPosition(
		({ currPos }) => {
			setPosition(currPos);
		}, [], elementRef
	);
	return (
		<ContactFormContainer id={'contact'} ref={elementRef}>
			<PilotContainer position={position.y}>
				<PilotImage/>
			</PilotContainer>
			<FormContent/>
		</ContactFormContainer>
	);
};

export default ContactForm;
