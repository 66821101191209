import styled from 'styled-components';

const ImageContentContainer = styled.div`
	width: 100%;
	order: ${props => props.order === 'left' ? 1 : 2};
	opacity: ${props => props.inView ? 1 : 0};
	transform: ${props => props.inView ? 'translateX(0)' : props.order === 'left' ? 'translateX(-24px)' : 'translateX(24px)'};
	transition: transform .7s cubic-bezier(.25,.1,.25,.1),opacity .7s cubic-bezier(0,0,.32,1.04);

	@media only screen and (min-width: 426px) {
		display: flex;
		width: 50%;
	}
`;

const StyledImage = styled.img`
	width: 100%;
`;

export { ImageContentContainer, StyledImage };
