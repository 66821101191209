import React from 'react';
import { TextContentContainer, StyledParagraph, StyledTitle } from './styled';

const TextContent = ({title, text, imageOrder, inView}) => {
	return (
		<TextContentContainer order={imageOrder} inView={inView}>
			<StyledTitle>{title}</StyledTitle>
			<StyledParagraph>{text}</StyledParagraph>
		</TextContentContainer>
	);
};

export default TextContent;
