import React from 'react';
import { PageFooter } from './styled';
import FooterMainLinks from '../FooterMainLinks';

const Footer = () => {
	return(
		<PageFooter>
			<FooterMainLinks/>
		</PageFooter>
	);
};

export default Footer;
