import React from 'react';
import { NavigationItem, StyledAnchorLink } from './styled';

const PrimaryNavigationItem = ({name, uri}) => {
	return (
		<NavigationItem>
			<StyledAnchorLink to={uri}>
				{name}
			</StyledAnchorLink>
		</NavigationItem>
	);
};

export default PrimaryNavigationItem;
