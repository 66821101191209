import { createGlobalStyle } from 'styled-components';
import './assets/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
a {
	text-decoration: none;
}

body {
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
}

form{
  display: block;
  margin-top: 0;
}

ul {
  list-style: none;
}

`;

export { GlobalStyle };
