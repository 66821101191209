import styled from 'styled-components';
import { Link } from 'gatsby';

const HomeLinkContainer = styled.div`
	display: inline-block;
	width: 10rem;
`;

const StyledLink = styled(Link)`
	color: white;
	text-decoration: none;
`;

const IconContainer = styled.div`
	width: 9rem;
`;

const Logo = styled.img`
  height: 50px;
  width: 50px;
  margin: 10px;
`;

export { HomeLinkContainer, StyledLink, IconContainer, Logo };
