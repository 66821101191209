import styled from 'styled-components';

const TextContentContainer = styled.div`
	width: 100%;
	order: ${props => props.order === 'left' ? 2 : 1};
	color: #074368;
	padding-bottom: 12px;
	opacity: ${props => props.inView ? 1 : 0};
	transform: ${props => props.inView ? 'translateY(0)' : 'translateY(-20px)'};
	transition: all .7s cubic-bezier(0,0,.32,1.04);

	@media only screen and (min-width: 426px) {
		padding: ${props => props.order === 'left' ? '0 0 0 24px' : '0 24px 0 0'};
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const StyledTitle = styled.h2`
	width: 100%;
	max-width: 440px;
	margin: auto 0 24px;
	font-family: 'Varsity', sans-serif;
  color: #074368;
  font-size: 40px;
  letter-spacing: 0.2em;
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;

	@media only screen and (min-width: 426px) {
		margin-bottom: 36px;
	}
	
	@media only screen and (min-width: 1200px) {
		padding-top: 80px;
	}
`;

const StyledParagraph = styled.p`
	width: 100%;
	max-width: 440px;
	margin-top: 0;
	line-height: 1.625;
	font-size: 18px;
	letter-spacing: .02em;
	text-align: center;
`;

export { TextContentContainer, StyledTitle, StyledParagraph };
