import React from 'react';
import slide1 from '../assets/images/Karuselli-AviateV1.jpg';
import slide2 from '../assets/images/Karuselli-NavigateV1.jpg';
import slide3 from '../assets/images/Karuselli-DominateV1.jpg';
import Logo from '../assets/images/mhgc.png';

import Layout from '../layout';
import SlideShowContainer from '../components/HeroSlider/SlideShowContainer';
import About from '../components/About/AboutContainer';
import FollowUs from '../components/Follow/FollowContainer';
import ContactForm from '../components/ContactForm/ContactFormContainer';
import SEO from '../components/seo';

const IndexPage = () => {
	const slides = [slide1, slide2, slide3];
	return (
		<Layout>
			<SEO />
			<SlideShowContainer
				slides={slides}
			/>
			<About
				image={Logo}
				text={'Mile High Gamers Club on elektronisen urheilun yhdistys, jonka jäsenet ovat suomalaisia ammattilentäjiä. Jäsenet ovat töissä eri organisaatioissa, mutta heitä yhdistää suomalainen lentäjäkulttuuri ja rakkaus pelaamiseen. Klubin toimintaan kuuluu tapahtumien järjestäminen, suoratoistopalveluiden tuottaminen sekä kilpapelaaminen LAN- ja online-ympäristöissä.'}
				imageOrder={'left'}
				title={'about mhgc'}
			/>
			<FollowUs
				heading={'follow us'}
				hashtag={'#flyMHGC'}
			/>
			<ContactForm/>
		</Layout>
	);
};

export default IndexPage;
