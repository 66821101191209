import styled from 'styled-components';
import TwitchIcon from '../../../assets/svgs/twitch.svg';
import InstagramIcon from '../../../assets/svgs/instagram.svg';
import YoutubeIcon from '../../../assets/svgs/youtube.svg';

const SocialMediaIconsListContainer = styled.div`
	padding: 1.5rem 0 1.5rem 0;
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (min-width: 769px){
		padding: 0.65rem 2rem 1.5rem 2rem;
	}
`;

const IconListItem = styled.div`
	padding-right: 20px;
	margin: 0;
	display: block;
	
	@media only screen and (min-width: 426px){
		padding-right: 30px;
	}
	
	@media only screen and (min-width: 769px){
		display: inline-block;
		padding-right: 40px;
	}
`;

const SocialMediaIconLink = styled.a`
	background-color: #005899;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	line-height: 24px;
	transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	box-shadow: 0 0 17px 0 rgba(0,0,0,0.2);

	&:hover {
		background: linear-gradient(#6d1212, #a41616);
		transform: translateY(-10px);
	}
	
	@media only screen and (min-width: 426px){
		width: 120px;
		height: 120px;
		line-height: 36px;
	}
	
	@media only screen and (min-width: 769px){
		width: 160px;
		height: 160px;
		line-height: 42px;
	}
`;

const SocialMediaIcon = styled(TwitchIcon)`
	height: 48px;
	width: 48px;
	fill: #ffffff;

	& * {
		fill: #ffffff;
	}
	
	@media only screen and (min-width: 426px){
		height: 72px;
		width: 72px;
	}
	
	@media only screen and (min-width: 769px){
		height: 96px;
		width: 96px;
	}
`;

const StyledTwitchIcon = SocialMediaIcon.withComponent(TwitchIcon);
const StyledInstagramIcon = SocialMediaIcon.withComponent(InstagramIcon);
const StyledYoutubeIcon = SocialMediaIcon.withComponent(YoutubeIcon);

export {
	SocialMediaIconsListContainer,
	StyledTwitchIcon,
	StyledInstagramIcon,
	StyledYoutubeIcon,
	IconListItem,
	SocialMediaIconLink
};
