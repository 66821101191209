import styled from 'styled-components';

const HeaderContainer = styled.div`
	position: fixed;
	top: 0;
	width: 100vw;
	display: flex;
	background: #074368;
	z-index: 2000;

	@media screen and (min-width: 769px){
		position: static;
	}
`;

const NavigationContainer = styled.div`
	z-index: 2000;
	position: inherit;
	top: 4rem;
	width: 100%;
	height: ${props => props.open ? '100%' : '0'};
	background: #074368;
	transition: 0.5s;
	overflow: hidden;

	@media screen and (min-width: 769px){
		position: inherit;
		display: flex;
		height: 5rem;
	}
`;

export { HeaderContainer, NavigationContainer };
