import styled from 'styled-components';

const StyledSlide = styled.div`
    transition: ${props => props.transition ? 'left 1s ease-in-out' : ''};
    left: ${props => props.position === 'onScreen' ? 0 : props.position === 'offScreenLeft' ? '-100vw' : '100vw'};
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom,#074368 50%,#f2f2f2 50%);
`;

const SlideImage = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding-top: 2rem;

    @media only screen and (min-width: 540px){
      object-fit: cover;
    }
    
    @media only screen and (min-width: 769px){
      padding-top: 0;
    }
`;

export {StyledSlide, SlideImage};
